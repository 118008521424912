import styled from "styled-components"
import { COLORS, FONTS, MOBILE_MAX_WIDTH } from "../../styles"
import Img from "gatsby-image"

export default {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  `,
  Title: styled.div`
    text-align: center;
    font-family: ${FONTS.SUBTITLE_FONT_FAMILY};
    color: ${COLORS.HOLLY_GREEN};
    font-size: 2rem;
    padding: 30px;
  `,
  Description: styled.div`
    font-family: ${FONTS.SUBTITLE_FONT_FAMILY};
    color: ${COLORS.HOLLY_GREEN};
    font-size: 1rem;
    padding: 30px 120px;
    text-align: center;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 30px;
    }
  `,
  Images: {
    Container: styled.div`
      width: 100%;
      height: fit-content;
    `,
    Title: styled.div`
      font-family: ${FONTS.SUBTITLE_FONT_FAMILY};
      color: ${COLORS.HOLLY_GREEN};
      font-size: 1.5rem;
      letter-spacing: 0.056rem;
      text-transform: uppercase;
      text-align: center;
      align-items: center;
      padding: 30px 0;
      background-color: ${COLORS.AQUA_HAZE};
    `,
    Element: styled.div`
      width: fit-content;
      height: fit-content;
      margin: 0.3rem;
    `,

    Image: styled(Img)`
      width: 293px;
      height: 293px;
    `,
  },
}
