import React, { FunctionComponent, useEffect, useState } from "react"
import Style from "./instafeed.style"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {
  getInstagramImagesByHashtag,
  InstagramPost,
} from "../../services/eventsService"

export interface InstagramPostNode {
  id: string
  link: string
  altText: string
  imageUrl: string
  type: string
  hashtag: string
}

interface Props {
  hashtag: string
  experienceSharingTitle?: string
  color?: string
  posts?: InstagramPostNode[]
}

const HashtagInstaFeed: FunctionComponent<Props> = ({
  hashtag,
  experienceSharingTitle,
  color,
  posts = [],
}: Props) => {
  /*
  const [posts, setPosts] = useState<InstagramPost[]>([])
  useEffect(() => {
    getInstagramImagesByHashtag(hashtag, numberOfExtraPosts).then(newPosts =>
      setPosts(newPosts)
    )
  }, [hashtag, numberOfExtraPosts])
  */
  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
    dots: true,
    arrows: true,
    autoplay: true,
    pauseOnHover: true,
    nextArrow: (
      <Style.CarouselNextArrowWrapper>
        <Style.CarouselNextArrow />
      </Style.CarouselNextArrowWrapper>
    ),
    prevArrow: (
      <Style.CarouselPrevArrowWrapper>
        <Style.CarouselPrevArrow />
      </Style.CarouselPrevArrowWrapper>
    ),
    adaptiveHeight: true,
  }

  if (!(posts && posts.length > 0)) return null

  return (
    <Style.Event.Container
      id="share_your_experience"
      style={{ backgroundColor: `${color}` }}
    >
      <Style.Event.Header>
        {experienceSharingTitle || "Share the experience"}
      </Style.Event.Header>
      <Style.Event.Hashtag>{hashtag}</Style.Event.Hashtag>
      <Style.PostsContainer>
        <Slider {...settings}>
          {posts.map((post, idx) => (
            <Style.Event.Post key={`one-post-${post.id}-${idx}`}>
              <Style.Event.InstaLink
                href={post.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Style.Event.InstaImage
                  src={post.imageUrl}
                  alt={post.altText}
                />
              </Style.Event.InstaLink>
              <Style.Event.InstaIcon />
            </Style.Event.Post>
          ))}
        </Slider>
      </Style.PostsContainer>
    </Style.Event.Container>
  )
}

export default HashtagInstaFeed
