import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/common/seo"
import Style from "../components/special-event/special-events.style"
import HashtagInstaFeed from "../components/instafeed/hashtagInstaFeed"
import Ticker from "../components/common/ticker"

interface Props {
  data: {
    allContentfulSpecialReportingPage: {
      edges: {
        node: {
          name: string
          title?: string
          description?: string
          content: {
            childMarkdownRemark: {
              html: string
            }
          }
          imagesTitle: string
          images?: {
            title: string
            fluid: any
          }[]
          hashtag?: string
          publish: boolean
        }
      }[]
    }
  }
}

const SpecialNewsPage = ({ data }: Props) => {
  const { edges } = data.allContentfulSpecialReportingPage
  if (edges.length > 1) return <p>Error. Come back later !</p>
  const {
    publish,
    name,
    description,
    content,
    imagesTitle,
    images,
    title,
    hashtag,
  } = edges[0].node
  if (!publish) return null
  return (
    <Layout>
      <SEO title={title || name} description={description} />
      <Style.Root>
        <Style.Title>{name}</Style.Title>
        <Style.Description
          dangerouslySetInnerHTML={{
            __html: content.childMarkdownRemark.html,
          }}
        />
        <Style.Images.Container>
          {hashtag ? (
            <HashtagInstaFeed
              hashtag={hashtag}
              experienceSharingTitle={imagesTitle}
              numberOfExtraPosts={11}
            />
          ) : (
            images && (
              <>
                <Style.Images.Title>{imagesTitle}</Style.Images.Title>
                <Ticker showNextPrevArrows>
                  {images.map(image => (
                    <Style.Images.Element key={image.title}>
                      <Style.Images.Image
                        fluid={image.fluid}
                        alt={image.title}
                      />
                    </Style.Images.Element>
                  ))}
                </Ticker>
              </>
            )
          )}
        </Style.Images.Container>
      </Style.Root>
    </Layout>
  )
}

export const query = graphql`
  query contentfulSpecialReportingPage {
    allContentfulSpecialReportingPage(filter: { publish: { eq: true } }) {
      edges {
        node {
          name
          content {
            childMarkdownRemark {
              html
            }
          }
          imagesTitle
          images {
            title
            fluid(quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
          hashtag
          publish
          title
        }
      }
    }
  }
`

export default SpecialNewsPage
